<template>
  <div class="content-wrapper user-page">
    <h1 class="mb-5">Licence Manager</h1>

    <b-card class="mb-4">
      <b-row class="my-1 justify-content-between">
        <b-col cols="4"
          ><b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Cerca cliente..."
            ></b-form-input> </b-input-group
        ></b-col>
        <b-col cols="auto">
          <b-button class="mb-2" v-b-toggle.sidebar-client
            >Nuovo Cliente</b-button
          >
        </b-col>
      </b-row>
    </b-card>

    <div class="cards">
      <b-row>
        <b-col cols="8">
          <b-card class="my-4">
            <h4>Stats</h4>
            <b-row>
              <b-col cols="3">
                <div>
                  <h6 style="height: 1px">Active/Total</h6>
                  <doughnut
                    style="height: 189px; padding-top: 3rem"
                    :labels="['Actives', 'Non Actives']"
                    :pieData="[pieData.actives, pieData.nonActives]"
                  ></doughnut>
                </div>
              </b-col>
              <b-col cols="3">
                <h6 style="height: 1px">Licence Type</h6>
                <doughnut
                  style="height: 189px; padding-top: 3rem"
                  :labels="pieData.licences"
                  :pieData="pieData.nLicences"
                ></doughnut
              ></b-col>
              <b-col cols="3">
                <h6 style="height: 1px">Current month Payments</h6>
                <doughnut
                  style="height: 189px; padding-top: 3rem"
                  :labels="pieData.names"
                  :pieData="pieData.prices"
                ></doughnut>
              </b-col>
              <b-col cols="3">
                <h6 style="height: 1px">Current month Users</h6>
                <doughnut
                  style="height: 189px; padding-top: 3rem"
                  :labels="pieData.names"
                  :pieData="pieData.nUsers"
                ></doughnut>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="4" class="align-self-end">
          <b-card class="my-4">
            <h4>Current month</h4>

            Total : {{ totale_computed }} € <br />
            Users: {{ utenti_computed }}</b-card
          >
        </b-col>
      </b-row>
    </div>

    <b-card class="mb-4">
      <b-table
        hover
        :items="items_client"
        :fields="fields_client"
        sort-icon-left
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        localSortBy="attivo"
        localSortDesc="true"
      >
        <template #cell(actions)="row">
          <b-dropdown
            size="sm"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <b-icon-gear-fill></b-icon-gear-fill>
            </template>
            <b-dropdown-item
              href="#"
              v-b-toggle.sidebar-client
              @click="
                currentItem = row.item;
                tmpItem = JSON.parse(JSON.stringify(row.item));
              "
              >Edit</b-dropdown-item
            >

            <b-dropdown-item
              href="#"
              @click="
                currentItem = row.item;
                showDeleteModal = true;
              "
              >Delete</b-dropdown-item
            >
          </b-dropdown>
        </template>
        <template #cell(apiEndpoint)="row">
          <code v-if="row.item.apiEndpoint"
            >https://api-{{ row.item.apiEndpoint }}.whappy.it</code
          >
          <code v-else> --- </code>
        </template>
        <template #cell(TotalPrice)="row">
          {{ row.item.TotalPrice }} €
        </template>
        <template #cell(attivo)="row">
          <b-icon-check-circle-fill
            class="ml-3"
            v-if="row.item.attivo"
            style="color: green"
          ></b-icon-check-circle-fill>
          <b-icon-dash-circle-fill
            class="ml-3"
            v-else
            style="color: grey"
          ></b-icon-dash-circle-fill>
        </template>
        <template #cell(billing)="row">
          <span
            style="font-weight: bolder"
            class="ml-3"
            role="button"
            v-b-toggle.sidebar-billing
            @click="
              currentItem = row.item;
              tmpItem = JSON.parse(JSON.stringify(row.item));
            "
            >$</span
          >

          <!-- <b-iconstack
            class="ml-3"
            role="button"
            v-b-toggle.sidebar-billing
            @click="
              currentItem = row.item;
              tmpItem = JSON.parse(JSON.stringify(row.item));
            "
          >
            <b-icon-cash></b-icon-cash>
          </b-iconstack> -->
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="mt-4 mb-1"
      ></b-pagination>
    </b-card>

    <b-modal
      v-model="showDeleteModal"
      title="Attenzione"
      ok-title="Conferma"
      cancel-title="Annulla"
      @hidden="resetSidebar_client"
      @ok="deleteItem(currentItem.id)"
      >Sei sicuro di voler rimuovere
      <span v-if="currentItem" style="font-weight: bold">
        {{ currentItem.name }}</span
      >
      dalla lista clienti?
    </b-modal>

    <b-sidebar
      id="sidebar-client"
      class="right-sidebar"
      right
      shadow
      lazy
      @hidden="resetSidebar_client"
    >
      <div class="content-wrapper my-3 mx-1">
        <h4 v-if="currentItem">Edit Cliente</h4>
        <h4 v-else>Nuovo Cliente</h4>
        <b-row>
          <b-col cols="8">
            <b-form-group label="Nome cliente">
              <b-form-input v-model="tmpItem.name"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="8">
            <b-form-group label="Tipo di licenza">
              <b-form-select
                v-model="tmpItem.licenceType"
                :options="licenceType_options"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="8">
            <b-form-group label="Endpoint dell'API">
              <b-input-group prepend="https://api-" append=".whappy.it">
                <b-input v-model="tmpItem.apiEndpoint"></b-input
              ></b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <b-form-checkbox v-model="tmpItem.attivo" switch>
                Utente attivo</b-form-checkbox
              >
            </b-form-group>
          </b-col>
        </b-row>
        <b-button size="lg" class="mb-2" @click="saveClient">Salva</b-button>
      </div>
    </b-sidebar>

    <b-sidebar
      id="sidebar-billing"
      class="right-sidebar"
      right
      shadow
      lazy
      @shown="loadBillingData(currentItem)"
      @hidden="resetSidebar_billing"
    >
      <div class="content-wrapper my-3 mx-1">
        <h4 v-if="currentItem">{{ currentItem.name }}</h4>

        <b-row>
          <b-col cols="8">
            <b-input-group class="mb-2">
              <b-input-group-prepend is-text>
                <b-icon icon="calendar"></b-icon>
              </b-input-group-prepend>
              <b-form-select
                @change="loadBillingData(currentItem)"
                v-model="year_selected"
                :options="year_options"
              ></b-form-select>
            </b-input-group>
          </b-col>
        </b-row>
        <b-overlay
          :show="overlay_billing"
          rounded="sm"
          class="overlay-placeholder"
        >
          <b-table hover :items="items_billing" :fields="fields_billing">
            <template #cell(apiUsage)="row">
              {{ (row.item.ApiCall / row.item.ApiTot).toFixed(2) }} %
            </template>
            <template #cell(PriceForUser)="row"
              >€ {{ row.item.PriceForUser }}</template
            >
            <template #cell(TotalPrice)="row"
              >€ {{ row.item.TotalPrice }}</template
            >
          </b-table>
        </b-overlay>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import axios from "axios";
import AxiosService from "../axiosServices/AxiosService";
import Doughnut from "../components/DoughnutChart.vue";

export default {
  name: "LicenceManager",
  service: null,
  components: {
    Doughnut,
  },
  created() {
    this.service = new AxiosService("LicenceManager");
  },
  mounted() {
    this.service.readCustomEndpoint("Licence/GetClientsList/").then((res) => {
      res.forEach(async (client) => {
        try {
          await axios
            .get(
              "https://api-" +
                client.apiEndpoint +
                ".whappy.it/api/Licence/GetUserCurrentMonth/" +
                client.licenceType
            )
            .then((res) => {
              client.TotalPrice = res.data.TotalPrice;
              client.Users = res.data.Users;
            });
        } catch {
          null;
        }

        this.items_client.unshift(client);
        this.pieData.names.unshift(client.name);
        this.pieData.prices.unshift(client.TotalPrice || 0);
        this.pieData.nUsers.unshift(client.Users);

        let currentLicenceIndex = this.pieData.licences.indexOf(
          client.licenceType
        );

        if (currentLicenceIndex == -1) {
          this.pieData.licences.unshift(client.licenceType);
          this.pieData.nLicences.unshift(1);
        } else {
          this.pieData.nLicences[currentLicenceIndex]++;
        }

        client.attivo ? this.pieData.actives++ : this.pieData.nonActives++;
      });
    });
  },
  data() {
    return {
      pieData: {
        names: [],
        prices: [],
        nUsers: [],
        licences: [],
        nLicences: [],
        actives: [],
        nonActives: [],
      },
      overlay_billing: false,

      showDeleteModal: false,
      isLoading: false,

      currentItem: null,
      tmpItem: { licenceType: null },

      year_selected: new Date().getFullYear(),
      year_options: [
        { value: null, text: "Select year to show" },
        { value: 2021, text: "2021" },
        { value: 2022, text: "2022" },
      ],

      licenceType_selected: null,
      licenceType_options: [
        { value: null, text: "Select licence type" },
        { value: "LITE", text: "Lite" },
        { value: "PLUS", text: "Plus" },
        { value: "PROFESSIONAL", text: "Professional" },
        { value: "ENTERPRISE", text: "Enterprise" },
      ],
      filter: "",
      currentPage: 1,
      perPage: 10,
      items_client: [],
      fields_client: [
        { key: "id", label: "ID", sortable: true },
        { key: "name", label: "Name", sortable: true },
        { key: "licenceType", label: "Licence type", sortable: true },
        { key: "apiEndpoint", label: "API endpoint", sortable: true },
        { key: "TotalPrice", label: "Current month Price", sortable: true },
        { key: "Users", label: "Current month Users", sortable: true },
        { key: "attivo", label: "Active", sortable: true },
        { key: "billing", label: "Billing" },
        { key: "actions", label: "Actions" },
      ],
      items_billing: [],
      fields_billing: [
        { key: "mese", label: "Month" },
        { key: "Users", label: "N° users" },
        { key: "apiUsage", label: "API usage" },
        { key: "PriceForUser", label: "Price for user" },
        { key: "TotalPrice", label: "Total price" },
      ],
    };
  },
  methods: {
    loadBillingData(currentItem) {
      this.overlay_billing = true;

      axios
        .get(
          "https://api-" +
            currentItem.apiEndpoint +
            ".whappy.it/api/Licence/GetUserByYear/" +
            this.year_selected +
            "/" +
            currentItem.licenceType
        )
        .then((res) => {
          if (res.data.length > 0) {
            this.items_billing = res.data;
          }
        })
        .catch(() => {
          this.$errorToast();
          return false;
        })
        .finally(() => {
          this.overlay_billing = false;
        });
    },
    saveClient() {
      this.isLoading = true;
      let item = { ...this.tmpItem };

      if (item.id) {
        this.service
          .putCustomEndpoint("Licence/UpdateClient/" + item.id, "", item)
          .then((res) => {
            if (res.id == item.id) {
              Object.keys(this.currentItem).forEach((element) => {
                if (Object.prototype.hasOwnProperty.call(item, element)) {
                  this.currentItem[element] = item[element];
                }
              });
              this.$root.$emit("bv::toggle::collapse", "sidebar-client");
              this.$successToast();
            } else {
              this.$errorToast();
              return false;
            }
          })
          .catch(() => {
            this.$errorToast();
            return false;
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.service
          .postCustomEndpoint("Licence/AddClient", "", this.tmpItem)
          .then((res) => {
            if (res.id) {
              this.items_client.push(item);
              this.$root.$emit("bv::toggle::collapse", "sidebar-client");
              this.$successToast();
            } else {
              this.$errorToast();
              return false;
            }
          })
          .catch(() => {
            this.$errorToast();
            return false;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    deleteItem(id) {
      this.service
        .deleteCustomEndpoint("Licence/DeleteClient", id)
        .then((res) => {
          if (res) {
            this.items_client = this.items_client.filter(
              (currentItem) => id !== currentItem.id
            );
            this.$successToast();
          } else {
            this.$errorToast();
            return false;
          }
        })
        .catch(() => {
          this.$errorToast();
          return false;
        });
    },
    resetSidebar_client() {
      this.tmpItem = { licenceType: null };
    },
    resetSidebar_billing() {
      this.year_selected = new Date().getFullYear();
      this.items_billing.splice(0);
    },
  },
  computed: {
    totalRows() {
      return this.items_client ? this.items_client.length : 0;
    },
    totale_computed() {
      let totale = 0;
      this.items_client.forEach((client) => {
        if (client.TotalPrice) {
          totale = totale + client.TotalPrice;
        } else {
          totale;
        }
      });
      return totale.toFixed(2);
    },
    utenti_computed() {
      let totale = 0;
      this.items_client.forEach((client) => {
        if (client.Users) {
          totale = totale + client.Users;
        } else {
          totale;
        }
      });
      return totale;
    },
  },
  watch: {
    filter: {
      handler() {
        this.currentPage = 1;
      },
    },
  },
};
</script>